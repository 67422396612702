<template>
    <div class="exam-container">
        <div class="common-first-part">
            <div>
                <p>考试题库</p>
                <span>依据考试大纲要求设计，练考评一体，学习节奏自由掌握，考试能力全面提升</span>
            </div>
            <img :src="require('@/assets/images/index/exam-1.png')" alt="">
        </div>
        <div class="second-part">
            <div>
                <p>2012年—2020年考试真题1000道 + 模拟题1400道</p>
                <span>每道题匹配相关知识点，包含答案、难度、文字解析</span>
            </div>
            <button class="learn-more"><a href="https://youjiao.wqketang.com/" target="_blank">立即体验</a></button>
        </div>
        <div class="third-part">
            <div>
                <p>学生端</p>
                <span>练习：真题测试、模拟题演练、知识点专项练习</span>
                <span>数据统计：错题本、知识点掌握情况</span>

                <p class="teacher">教师端</p>
                <span>创建习题、创建试卷、创建班级、组织考试、试卷成绩统计</span>
            </div>
            <img :src="require('@/assets/images/index/exam-2.png')" alt="">
        </div>
        <div class="forth-part">
            <p>在线课程</p>
            <span>配备笔试面试辅导讲解课程，包含PPT、典型题型讲解、</span>
            <span>面试答题技巧等资源</span>
            <span>由主任教授团队解读出题思路，归纳解题要领</span>
            <img :src="require('@/assets/images/index/exam-3.png')" alt="">
            <button class="learn-more"><a href="https://www.wqketang.com/course/89415" target="_blank">立即体验</a></button>
        </div>
    </div>
</template>
<script>
export default {
    components: {  },
    props: {},
    data() {
        return {
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.exam-container {
    .second-part {
        background-size: cover;
        padding: 70px;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        color: #0768AB;
        &>div {
            height: 118px;
            &>p {
                font-size: 24px;
                line-height: 42px;
            }
            &>span {
                font-size: 18px;
                line-height: 38px;
                display: block;
            }
        }
    }
    .third-part {
        height: 440px;
        background: #F6F9FD;
        padding: 39px 0 0 120px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        &>div {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            &>p {
                margin-bottom: 10px;
                font-size: 18px;
                font-weight: 600;
            }
            .teacher {
                margin-top: 40px;
            }
            &>span {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 26px;
                display: block;
            }
        }
        &>img {
            height: 400px;
        }
    }
    .forth-part {
        padding: 54px 0 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        &>p {
            font-size: 40px;
            font-weight: 500;
            color: #000000;
            line-height: 56px;
            margin-bottom: 20px;
        }
        &>span {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            display: block;
        }
        &>img {
            width: 656px;
            margin-bottom: 24px;
            margin-top: 34px;
        }
    }
}
</style>